

// import React from 'react';

// const Subscription = () => {
//   return (
//     <div className="text-center w-[90%] max-w-[100%] text-black bg-white p-4 rounded-lg mx-auto">
//       {/* Responsive Heading */}
//       <div className="text-[1rem] md:text-[1.2rem] lg:text-[1.5rem] mb-2">
//         Stay in the loop with the latest updates!
//       </div>

//       {/* Responsive Paragraph */}
//       <p className="font-roboto text-[0.7rem] md:text-[0.8rem] lg:text-[0.9rem] text-gray-600 mb-4">
//         Subscribe now to receive notifications about new events, exciting campaigns, and captivating
//         blog posts directly to your inbox. Don't miss out on any important information or engaging
//         content.
//       </p>

//       {/* Responsive Input and Button */}
//       <div className="font-roboto flex flex-col md:flex-col gap-2 justify-center items-center mb-2">
//         <input
//           type="email"
//           placeholder="Enter email address"
//           className="pl-2 w-full sm:w-[16rem] outline-none focus:outline-none border-2 border-gray-300 rounded-lg py-2"
//         />
//         <button className="px-4 py-2 w-full sm:w-auto text-white bg-green rounded-lg hover:bg-green-600 transition-all duration-300">
//           Subscribe
//         </button>
//       </div>

//       {/* Responsive Footer Text */}
//       <p className="font-roboto text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] text-gray-600">
//         Join our subscription list today and be the first to know!
//       </p>
//     </div>
//   );
// };

// export default Subscription;
import React, { useState } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2

const Subscription = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();

    // Validate the email before showing the alert
    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid email address!',
      });
      return;
    }

    // Show success alert on subscription
    Swal.fire({
      icon: 'success',
      title: 'Subscribed Successfully!',
      text: 'You will receive updates to your email.',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    });

    // Reset email field
    setEmail('');
  };

  return (
    <div className="text-center w-[90%] max-w-[100%] text-black bg-white p-4 rounded-lg mx-auto">
      {/* Responsive Heading */}
      <div className="text-[1rem] md:text-[1.2rem] lg:text-[1.5rem] mb-2">
        Stay in the loop with the latest updates!
      </div>

      {/* Responsive Paragraph */}
      <p className="font-roboto text-[0.7rem] md:text-[0.8rem] lg:text-[0.9rem] text-gray-600 mb-4">
        Subscribe now to receive notifications about new events, exciting campaigns, and captivating
        blog posts directly to your inbox. Don't miss out on any important information or engaging
        content.
      </p>

      {/* Responsive Input and Button */}
      <div className="font-roboto flex flex-col md:flex-col gap-2 justify-center items-center mb-2">
        <input
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Capture email input
          className="pl-2 w-full sm:w-[16rem] outline-none focus:outline-none border-2 border-gray-300 rounded-lg py-2"
        />
        <button
          onClick={handleSubscribe} // Trigger subscription handler on click
          className="px-4 py-2 w-full sm:w-auto text-white bg-green rounded-lg hover:bg-green-600 transition-all duration-300"
        >
          Subscribe
        </button>
      </div>

      {/* Responsive Footer Text */}
      <p className="font-roboto text-[0.6rem] md:text-[0.7rem] lg:text-[0.8rem] text-gray-600">
        Join our subscription list today and be the first to know!
      </p>
    </div>
  );
};

export default Subscription;
