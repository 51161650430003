import { useNavigate } from "react-router-dom";

const Campain = ({ Data }) => {
  const navigate = useNavigate();

  return (
    <div>
      {Data?.map((x) => (
        <div key={x.id} className="flex flex-col sm:flex-row gap-5 p-4 md:pb-10 lg:ml-6">
          {/* Image Section */}
          <div className="w-full sm:w-1/2">
            <img
              className="rounded-2xl w-full h-auto object-cover"
              src={process.env.REACT_APP_API_IMAGE_URL + x?.attributes?.imageURL?.data[0].attributes?.url}
              alt="campaign"
            />
          </div>

          {/* Text Content Section */}
          <div className="w-full sm:w-1/2 flex flex-col justify-between">
            <h1 className="text-xl sm:text-2xl font-bold text-white my-3 sm:my-0">
              {x?.attributes?.name}
            </h1>
            <p className="text-sm sm:text-base text-center sm:text-left line-clamp-5 text-white mb-3 sm:mb-0">
              {x?.attributes?.description}
            </p>

            <div className="flex justify-end mt-2 sm:mt-4">
              <button
                className="bg-green text-white rounded-xl p-2 sm:p-3 hover:bg-green-700 transition duration-300"
                onClick={() => {
                  navigate("/campaings/" + x.id);
                  window.scrollTo(0, 0);
                }}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Campain;
