import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsLogin }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Initialize the Google Sign-In button
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Use your Client ID from Google Cloud Console
            callback: handleGoogleSignIn,
        });

        // Render the Google Sign-In button
        window.google.accounts.id.renderButton(
            document.getElementById('google-signin-btn'),
            {
                theme: 'outline',
                size: 'large',
            }
        );
    }, []);

    const handleGoogleSignIn = async (response) => {
        console.log('Encoded JWT ID token: ', response.credential);
        try {
            // You can optionally send the ID token to the backend for verification
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/verifyGoogleToken`, {
                token: response.credential,
            });

            if (data.success) {
                setMessage('Success: Logged in with Google!');
                navigate('/payment');
            } else {
                setMessage('Error: Google login failed');
            }
        } catch (error) {
            console.error('Google Login Error:', error);
            setMessage('Error: Google login failed');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = formData;

        if (email && password) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
                    params: { email, password },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    },
                });

                const data = response.data;

                if (data) {
                    setMessage('Success: Login successfully!');
                    navigate("/payment");
                } else {
                    setMessage('No account found. Please create a new account.');
                    setIsLogin(false);
                }
            } catch (error) {
                if (error.response) {
                    setMessage(`Error: ${error.response.data.message}`);
                } else if (error.request) {
                    setMessage('Error: No response from server');
                } else {
                    setMessage(`Error: ${error.message}`);
                }
            }
        } else {
            setMessage('Error: Please fill out all fields');
        }
    };

    return (
        <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full md:w-[40%] items-center max-w-4xl transition duration-1000 ease-out">
            <h2 className="p-3 text-3xl font-bold text-darkgreen">Constantine Foundation</h2>
            <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
            <h3 className="text-xl font-semibold text-darkgreen pt-2">Sign In!</h3>
            
            {/* Google Sign-In Button */}
            <div id="google-signin-btn" className="m-4"></div>

            {/* Traditional Email/Password Sign In Form */}
            <form className="flex flex-col items-center justify-center w-[80%]" onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-darkgreen focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <button
                    type="submit"
                    className="rounded-2xl m-2 text-white bg-darkgreen w-2/5 px-4 py-2 shadow-md hover:text-darkgreen hover:bg-white transition duration-200 ease-in"
                >
                    Sign In
                </button>
            </form>

            {/* Display any messages */}
            {message && (
                <p className={`mt-4 text-sm font-medium ${message.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>
                    {message}
                </p>
            )}

            <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
            <p className="text-darkgreen mt-4 text-sm">Don't have an account?</p>
            <p className="text-darkgreen mb-4 text-sm font-medium cursor-pointer" onClick={() => setIsLogin(false)}>
                Create a New Account.
            </p>
        </div>
    );
};

Login.propTypes = {
    setIsLogin: PropTypes.func.isRequired,
};

export default Login;
