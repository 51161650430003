import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    setCookiesAndCache();
  };

  const handleDecline = () => {
    setShowBanner(false);
  };

  const setCookiesAndCache = () => {
    document.cookie = "username=John Doe";
    // Additional cookies and cache settings can be added here
  };

  if (!showBanner) return null;

  return (
    <div style={styles.banner} className=' z-50 h-[5rem]'>
      <p>We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.</p>
      <button onClick={handleAccept} style={styles.button}>Accept</button>
      <button onClick={handleDecline} style={styles.button}>Decline</button>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#000',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
  },
  button: {
    margin: '0 5px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
};

export default CookieConsent;

