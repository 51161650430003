// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from 'axios'; // Import Axios
// import Donate from '../components/donors';

// const Donater = () => {
// const navigate = useNavigate();
// const [formData, setFormData] = useState({
//   firstName: "",
//   lastName: "",
//   amount: "",
//   purpose: "",
//   showName: "Yes",
// });

// const handleChange = (e) => {
//   const { id, value } = e.target;
//   setFormData((prevData) => ({
//     ...prevData,
//     [id]: value,
//   }));
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   // Construct the payload with a ternary operator for showName
//   const payload = {
//     data: {
//       firstName: formData.firstName,
//       lastName: formData.lastName,
//       amount: formData.amount,
//       purpose: formData.purpose,
//       showName: formData.showName === "Yes" ? true : false,
//     },
//   };

//   try {
//     const response = await axios.post(`${process.env.REACT_APP_API_URL}/donors`, payload, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//       },
//     });

//     if (response.status !== 200) {
//       throw new Error(response.data.error.message || 'Something went wrong');
//     }

//     const result = response.data;
//     console.log('Success:', result);
//     alert('Donation form submitted successfully');
//     navigate("/auth");
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };


//     return (
//         <React.Fragment>
//             <div className="flex flex-row">
//                 <section className="max-w-[70%] p-6 mx-auto bg-green rounded-md shadow-md dark:bg-gray-800 my-20">
//                     <h1 className="text-xl font-bold text-white capitalize dark:text-white">
//                         Donation form
//                     </h1>
//                     <form onSubmit={handleSubmit}>
//                         <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
//                             <div>
//                                 <label className="text-white dark:text-gray-200" htmlFor="firstName">
//                                     First Name
//                                 </label>
//                                 <input
//                                     id="firstName"
//                                     type="text"
//                                     value={formData.firstName}
//                                     onChange={handleChange}
//                                     className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
//                                 />
//                             </div>
//                             <div>
//                                 <label className="text-white dark:text-gray-200" htmlFor="amount">
//                                     Amount
//                                 </label>
//                                 <input
//                                     id="amount"
//                                     type="number"
//                                     value={formData.amount}
//                                     onChange={handleChange}
//                                     className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
//                                 />
//                             </div>
//                             <div>
//                                 <label className="text-white dark:text-gray-200" htmlFor="lastName">
//                                     Last Name
//                                 </label>
//                                 <input
//                                     id="lastName"
//                                     type="text"
//                                     value={formData.lastName}
//                                     onChange={handleChange}
//                                     className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
//                                 />
//                             </div>
//                             <div>
//                                 <label className="text-white dark:text-gray-200" htmlFor="purpose">
//                                     Purpose of donation
//                                 </label>
//                                 <input
//                                     id="purpose"
//                                     type="text"
//                                     value={formData.purpose}
//                                     onChange={handleChange}
//                                     className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
//                                 />
//                             </div>
//                             <div>
//                                 <label className="text-white dark:text-gray-200" htmlFor="showName">
//                                     Would you like to show your name on the website?
//                                 </label>
//                                 <select
//                                     id="showName"
//                                     value={formData.showName}
//                                     onChange={handleChange}
//                                     className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
//                                 >
//                                     <option>Yes</option>
//                                     <option>No</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="flex justify-end mt-6">
//                         {/* <Link onClick={() => window.scrollTo(0, 0)} to={"/login"}> */}
//                             <button className="px-6 py-2 bg-white leading-5 text-primary hover:bg-primary hover:text-white transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600">
//                                 Donate
//                             </button>
//                         {/* </Link> */}
//                         </div>
//                     </form>
//                 </section>
//                 <div className="w-[30%] mt-[4rem]">
//                     <Donate />
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default Donater;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Donate from '../components/donors';

const Donater = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        amount: "",
        purpose: "",
        showName: "Yes",
        currency: "USD", // Default currency
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            data: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                amount: formData.amount,
                purpose: formData.purpose,
                showName: formData.showName === "Yes",
                // currency: formData.currency,
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/donors`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.data.error.message || 'Something went wrong');
            }

            const result = response.data;
            console.log('Success:', result);
            alert('Donation form submitted successfully');
            navigate("/auth");
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="flex flex-row">
                <section className="max-w-[70%] p-6 mx-auto bg-green rounded-md shadow-md dark:bg-gray-800 my-20">
                    <h1 className="text-xl font-bold text-white capitalize dark:text-white">
                        Donation form
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="firstName">
                                    First Name
                                </label>
                                <input
                                    id="firstName"
                                    type="text"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                />
                            </div>
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="amount">
                                    Amount
                                </label>
                                <input
                                    id="amount"
                                    type="number"
                                    value={formData.amount}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                />
                            </div>
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="lastName">
                                    Last Name
                                </label>
                                <input
                                    id="lastName"
                                    type="text"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                />
                            </div>
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="purpose">
                                    Purpose of donation
                                </label>
                                <input
                                    id="purpose"
                                    type="text"
                                    value={formData.purpose}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                />
                            </div>
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="showName">
                                    Would you like to show your name on the website?
                                </label>
                                <select
                                    id="showName"
                                    value={formData.showName}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div>
                                <label className="text-white dark:text-gray-200" htmlFor="currency">
                                    Currency
                                </label>
                                <select
                                    id="currency"
                                    value={formData.currency}
                                    onChange={handleChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                >
                                    <option value="USD">US Dollar</option>
                                    <option value="CDI">CDI</option>
                                    <option value="EUR">Euro</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex justify-end mt-6">
                            <button type="submit" className="px-6 py-2 bg-white leading-5 text-primary hover:bg-primary hover:text-white transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600">
                                Donate
                            </button>
                        </div>
                    </form>
                </section>
                <div className="w-[30%] mt-[4rem]">
                    <Donate />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Donater;
